import Afghan from "@/assets/flag-icons/af.svg";
import Albania from "@/assets/flag-icons/al.svg";
import Algeria from "@/assets/flag-icons/dz.svg";
import Andorra from "@/assets/flag-icons/ad.svg";
import Angola from "@/assets/flag-icons/ao.svg";
import AntiguaB from "@/assets/flag-icons/ag.svg";
import Argentina from "@/assets/flag-icons/ar.svg";
import Armenia from "@/assets/flag-icons/am.svg";
import Australia from "@/assets/flag-icons/au.svg";
import Austria from "@/assets/flag-icons/at.svg";
import Azerbaijan from "@/assets/flag-icons/az.svg";
import Bahamas from "@/assets/flag-icons/bs.svg";
import Bahrain from "@/assets/flag-icons/bh.svg";
import Bangladesh from "@/assets/flag-icons/bd.svg";
import Barbados from "@/assets/flag-icons/bb.svg";
import Belarus from "@/assets/flag-icons/by.svg";
import Belgium from "@/assets/flag-icons/be.svg";
import Belize from "@/assets/flag-icons/bz.svg";
import Benin from "@/assets/flag-icons/bj.svg";
import Bhutan from "@/assets/flag-icons/bt.svg";
import Bolivia from "@/assets/flag-icons/bo.svg";
import BosniaH from "@/assets/flag-icons/ba.svg";
import Botswana from "@/assets/flag-icons/bw.svg";
import Brazil from "@/assets/flag-icons/br.svg";
import Brunei from "@/assets/flag-icons/bn.svg";
import Bulgaria from "@/assets/flag-icons/bg.svg";
import BurkinaF from "@/assets/flag-icons/bf.svg";
import Burundi from "@/assets/flag-icons/bi.svg";
import CaboVerde from "@/assets/flag-icons/cv.svg";
import Cambodia from "@/assets/flag-icons/kh.svg";
import Cameroon from "@/assets/flag-icons/cm.svg";
import Canada from "@/assets/flag-icons/ca.svg";
import CentralAfrica from "@/assets/flag-icons/cf.svg";
import Chad from "@/assets/flag-icons/td.svg";
import Chile from "@/assets/flag-icons/cl.svg";
import China from "@/assets/flag-icons/cn.svg";
import Colombia from "@/assets/flag-icons/co.svg";
import Comoros from "@/assets/flag-icons/km.svg";
import CongoDr from "@/assets/flag-icons/cd.svg";
import CongoR from "@/assets/flag-icons/cg.svg";
import CostaRica from "@/assets/flag-icons/cr.svg";
import Croatia from "@/assets/flag-icons/hr.svg";
import Cuba from "@/assets/flag-icons/cu.svg";
import Cyprus from "@/assets/flag-icons/cy.svg";
import CzechRepublic from "@/assets/flag-icons/cz.svg";
import Denmark from "@/assets/flag-icons/dk.svg";
import Djibouti from "@/assets/flag-icons/dj.svg";
import Dominica from "@/assets/flag-icons/dm.svg";
import DominicanRepublic from "@/assets/flag-icons/do.svg";
import Ecuador from "@/assets/flag-icons/ec.svg";
import Egypt from "@/assets/flag-icons/eg.svg";
import ElSalvador from "@/assets/flag-icons/sv.svg";
import EquatorialG from "@/assets/flag-icons/gq.svg";
import Eritrea from "@/assets/flag-icons/er.svg";
import Estonia from "@/assets/flag-icons/ee.svg";
import Eswatini from "@/assets/flag-icons/sz.svg";
import Ethiopia from "@/assets/flag-icons/et.svg";
import Fiji from "@/assets/flag-icons/fj.svg";
import Finland from "@/assets/flag-icons/fi.svg";
import France from "@/assets/flag-icons/fr.svg";
import Gabon from "@/assets/flag-icons/ga.svg";
import Gambia from "@/assets/flag-icons/gm.svg";
import Georgia from "@/assets/flag-icons/ge.svg";
import Germany from "@/assets/flag-icons/de.svg";
import Ghana from "@/assets/flag-icons/gh.svg";
import Greece from "@/assets/flag-icons/gr.svg";
import Grenada from "@/assets/flag-icons/gd.svg";
import Guatemala from "@/assets/flag-icons/gt.svg";
import Guinea from "@/assets/flag-icons/gn.svg";
import GuineaBissau from "@/assets/flag-icons/gw.svg";
import Guyana from "@/assets/flag-icons/gy.svg";
import Haiti from "@/assets/flag-icons/ht.svg";
import Honduras from "@/assets/flag-icons/hn.svg";
import Hungary from "@/assets/flag-icons/hu.svg";
import Iceland from "@/assets/flag-icons/is.svg";
import India from "@/assets/flag-icons/in.svg";
import Indonesia from "@/assets/flag-icons/id.svg";
import Iran from "@/assets/flag-icons/ir.svg";
import Iraq from "@/assets/flag-icons/iq.svg";
import Ireland from "@/assets/flag-icons/ie.svg";
import Israel from "@/assets/flag-icons/il.svg";
import Italy from "@/assets/flag-icons/it.svg";
import Jamaica from "@/assets/flag-icons/jm.svg";
import Japan from "@/assets/flag-icons/jp.svg";
import Jordan from "@/assets/flag-icons/jo.svg";
import Kazakhstan from "@/assets/flag-icons/kz.svg";
import Kenya from "@/assets/flag-icons/ke.svg";
import Kiribati from "@/assets/flag-icons/ki.svg";
import KoreaNorth from "@/assets/flag-icons/kp.svg";
import KoreaSouth from "@/assets/flag-icons/kr.svg";
import Kosovo from "@/assets/flag-icons/xk.svg";
import Kuwait from "@/assets/flag-icons/kw.svg";
import Kyrgyzstan from "@/assets/flag-icons/kg.svg";
import Laos from "@/assets/flag-icons/la.svg";
import Latvia from "@/assets/flag-icons/lv.svg";
import Lebanon from "@/assets/flag-icons/lb.svg";
import Lesotho from "@/assets/flag-icons/ls.svg";
import Liberia from "@/assets/flag-icons/lr.svg";
import Libya from "@/assets/flag-icons/ly.svg";
import Liechtenstein from "@/assets/flag-icons/li.svg";
import Lithuania from "@/assets/flag-icons/lt.svg";
import Luxembourg from "@/assets/flag-icons/lu.svg";
import Madagascar from "@/assets/flag-icons/mg.svg";
import Malawi from "@/assets/flag-icons/mw.svg";
import Malaysia from "@/assets/flag-icons/my.svg";
import Maldives from "@/assets/flag-icons/mv.svg";
import Mali from "@/assets/flag-icons/ml.svg";
import Malta from "@/assets/flag-icons/mt.svg";
import MarshallIslands from "@/assets/flag-icons/mh.svg";
import Mauritania from "@/assets/flag-icons/mr.svg";
import Mauritius from "@/assets/flag-icons/mu.svg";
import Mexico from "@/assets/flag-icons/mx.svg";
import Micronesia from "@/assets/flag-icons/fm.svg";
import Moldova from "@/assets/flag-icons/md.svg";
import Monaco from "@/assets/flag-icons/mc.svg";
import Mongolia from "@/assets/flag-icons/mn.svg";
import Montenegro from "@/assets/flag-icons/me.svg";
import Morocco from "@/assets/flag-icons/ma.svg";
import Mozambique from "@/assets/flag-icons/mz.svg";
import Myanmar from "@/assets/flag-icons/mm.svg";
import Namibia from "@/assets/flag-icons/na.svg";
import Nauru from "@/assets/flag-icons/nr.svg";
import Nepal from "@/assets/flag-icons/np.svg";
import Netherlands from "@/assets/flag-icons/nl.svg";
import NewZealand from "@/assets/flag-icons/nz.svg";
import Nicaragua from "@/assets/flag-icons/ni.svg";
import Niger from "@/assets/flag-icons/ne.svg";
import Nigeria from "@/assets/flag-icons/ng.svg";
import NorthMacedonia from "@/assets/flag-icons/mk.svg";
import Norway from "@/assets/flag-icons/no.svg";
import Oman from "@/assets/flag-icons/om.svg";
import Pakistan from "@/assets/flag-icons/pk.svg";
import Palau from "@/assets/flag-icons/pw.svg";
import Panama from "@/assets/flag-icons/pa.svg";
import PapuaNewGuinea from "@/assets/flag-icons/pg.svg";
import Paraguay from "@/assets/flag-icons/py.svg";
import Peru from "@/assets/flag-icons/pe.svg";
import Philippines from "@/assets/flag-icons/ph.svg";
import Poland from "@/assets/flag-icons/pl.svg";
import Portugal from "@/assets/flag-icons/pt.svg";
import Qatar from "@/assets/flag-icons/qa.svg";
import Romania from "@/assets/flag-icons/ro.svg";
import Russia from "@/assets/flag-icons/ru.svg";
import Rwanda from "@/assets/flag-icons/rw.svg";
import SaintKN from "@/assets/flag-icons/kn.svg";
import SaintLucia from "@/assets/flag-icons/lc.svg";
import SaintVG from "@/assets/flag-icons/vc.svg";
import Samoa from "@/assets/flag-icons/ws.svg";
import SanMarino from "@/assets/flag-icons/sm.svg";
import SaoTomeP from "@/assets/flag-icons/st.svg";
import SaudiArabia from "@/assets/flag-icons/sa.svg";
import Senegal from "@/assets/flag-icons/sn.svg";
import Serbia from "@/assets/flag-icons/rs.svg";
import Seychelles from "@/assets/flag-icons/sc.svg";
import SierraLeone from "@/assets/flag-icons/sl.svg";
import Singapore from "@/assets/flag-icons/sg.svg";
import Slovakia from "@/assets/flag-icons/sk.svg";
import Slovenia from "@/assets/flag-icons/si.svg";
import SolomonIslands from "@/assets/flag-icons/sb.svg";
import Somalia from "@/assets/flag-icons/so.svg";
import SouthAfrica from "@/assets/flag-icons/za.svg";
import SouthSudan from "@/assets/flag-icons/ss.svg";
import Spain from "@/assets/flag-icons/es.svg";
import SriLanka from "@/assets/flag-icons/lk.svg";
import Sudan from "@/assets/flag-icons/sd.svg";
import Suriname from "@/assets/flag-icons/sr.svg";
import Sweden from "@/assets/flag-icons/se.svg";
import Switzerland from "@/assets/flag-icons/ch.svg";
import Syria from "@/assets/flag-icons/sy.svg";
import Taiwan from "@/assets/flag-icons/tw.svg";
import Tajikistan from "@/assets/flag-icons/tj.svg";
import Tanzania from "@/assets/flag-icons/tz.svg";
import Thailand from "@/assets/flag-icons/th.svg";
import TimorLeste from "@/assets/flag-icons/tl.svg";
import Togo from "@/assets/flag-icons/tg.svg";
import Tonga from "@/assets/flag-icons/to.svg";
import TrinidadT from "@/assets/flag-icons/tt.svg";
import Tunisia from "@/assets/flag-icons/tn.svg";
import Turkey from "@/assets/flag-icons/tr.svg";
import Turkmenistan from "@/assets/flag-icons/tm.svg";
import Tuvalu from "@/assets/flag-icons/tv.svg";
import Uganda from "@/assets/flag-icons/ug.svg";
import Ukraine from "@/assets/flag-icons/ua.svg";
import UnitedArabEmirates from "@/assets/flag-icons/ae.svg";
import UnitedKingdom from "@/assets/flag-icons/gb.svg";
import UnitedStates from "@/assets/flag-icons/us.svg";
import Uruguay from "@/assets/flag-icons/uy.svg";
import Uzbekistan from "@/assets/flag-icons/uz.svg";
import Vanuatu from "@/assets/flag-icons/vu.svg";
import Venezuela from "@/assets/flag-icons/ve.svg";
import Vietnam from "@/assets/flag-icons/vn.svg";
import Yemen from "@/assets/flag-icons/ye.svg";
import Zambia from "@/assets/flag-icons/zm.svg";
import Zimbabwe from "@/assets/flag-icons/zw.svg";

export const FlagMap: {
  [x: string]: JSX.Element;
} = {
  afghan: <Afghan className="w-4 h-3" />,
  albania: <Albania className="w-4 h-3" />,
  algeria: <Algeria className="w-4 h-3" />,
  andorra: <Andorra className="w-4 h-3" />,
  angola: <Angola className="w-4 h-3" />,
  "antigua-b": <AntiguaB className="w-4 h-3" />,
  argentina: <Argentina className="w-4 h-3" />,
  armenia: <Armenia className="w-4 h-3" />,
  australia: <Australia className="w-4 h-3" />,
  austria: <Austria className="w-4 h-3" />,
  azerbaijan: <Azerbaijan className="w-4 h-3" />,
  bahamas: <Bahamas className="w-4 h-3" />,
  bahrain: <Bahrain className="w-4 h-3" />,
  bangladesh: <Bangladesh className="w-4 h-3" />,
  barbados: <Barbados className="w-4 h-3" />,
  belarus: <Belarus className="w-4 h-3" />,
  belgium: <Belgium className="w-4 h-3" />,
  belize: <Belize className="w-4 h-3" />,
  benin: <Benin className="w-4 h-3" />,
  bhutan: <Bhutan className="w-4 h-3" />,
  bolivia: <Bolivia className="w-4 h-3" />,
  "bosnia-h": <BosniaH className="w-4 h-3" />,
  botswana: <Botswana className="w-4 h-3" />,
  brazil: <Brazil className="w-4 h-3" />,
  brunei: <Brunei className="w-4 h-3" />,
  bulgaria: <Bulgaria className="w-4 h-3" />,
  "burkina-f": <BurkinaF className="w-4 h-3" />,
  burundi: <Burundi className="w-4 h-3" />,
  "cabo-verde": <CaboVerde className="w-4 h-3" />,
  cambodia: <Cambodia className="w-4 h-3" />,
  cameroon: <Cameroon className="w-4 h-3" />,
  canada: <Canada className="w-4 h-3" />,
  "cent-afr": <CentralAfrica className="w-4 h-3" />,
  chad: <Chad className="w-4 h-3" />,
  chile: <Chile className="w-4 h-3" />,
  china: <China className="w-4 h-3" />,
  colombia: <Colombia className="w-4 h-3" />,
  comoros: <Comoros className="w-4 h-3" />,
  "congo-dr": <CongoDr className="w-4 h-3" />,
  "congo-r": <CongoR className="w-4 h-3" />,
  "costa-rica": <CostaRica className="w-4 h-3" />,
  croatia: <Croatia className="w-4 h-3" />,
  cuba: <Cuba className="w-4 h-3" />,
  cyprus: <Cyprus className="w-4 h-3" />,
  "czech-rep": <CzechRepublic className="w-4 h-3" />,
  denmark: <Denmark className="w-4 h-3" />,
  djibouti: <Djibouti className="w-4 h-3" />,
  dominica: <Dominica className="w-4 h-3" />,
  "dominican-r": <DominicanRepublic className="w-4 h-3" />,
  ecuador: <Ecuador className="w-4 h-3" />,
  egypt: <Egypt className="w-4 h-3" />,
  "el-salvador": <ElSalvador className="w-4 h-3" />,
  "equatorial-g": <EquatorialG className="w-4 h-3" />,
  eritrea: <Eritrea className="w-4 h-3" />,
  estonia: <Estonia className="w-4 h-3" />,
  eswatini: <Eswatini className="w-4 h-3" />,
  ethiopia: <Ethiopia className="w-4 h-3" />,
  fiji: <Fiji className="w-4 h-3" />,
  finland: <Finland className="w-4 h-3" />,
  france: <France className="w-4 h-3" />,
  gabon: <Gabon className="w-4 h-3" />,
  gambia: <Gambia className="w-4 h-3" />,
  georgia: <Georgia className="w-4 h-3" />,
  germany: <Germany className="w-4 h-3" />,
  ghana: <Ghana className="w-4 h-3" />,
  greece: <Greece className="w-4 h-3" />,
  grenada: <Grenada className="w-4 h-3" />,
  guatemala: <Guatemala className="w-4 h-3" />,
  guinea: <Guinea className="w-4 h-3" />,
  "guinea-b": <GuineaBissau className="w-4 h-3" />,
  guyana: <Guyana className="w-4 h-3" />,
  haiti: <Haiti className="w-4 h-3" />,
  honduras: <Honduras className="w-4 h-3" />,
  hungary: <Hungary className="w-4 h-3" />,
  iceland: <Iceland className="w-4 h-3" />,
  india: <India className="w-4 h-3" />,
  indonesia: <Indonesia className="w-4 h-3" />,
  iran: <Iran className="w-4 h-3" />,
  iraq: <Iraq className="w-4 h-3" />,
  ireland: <Ireland className="w-4 h-3" />,
  israel: <Israel className="w-4 h-3" />,
  italy: <Italy className="w-4 h-3" />,
  jamaica: <Jamaica className="w-4 h-3" />,
  japan: <Japan className="w-4 h-3" />,
  jordan: <Jordan className="w-4 h-3" />,
  kazakhstan: <Kazakhstan className="w-4 h-3" />,
  kenya: <Kenya className="w-4 h-3" />,
  kiribati: <Kiribati className="w-4 h-3" />,
  "korea-north": <KoreaNorth className="w-4 h-3" />,
  "korea-south": <KoreaSouth className="w-4 h-3" />,
  kosovo: <Kosovo className="w-4 h-3" />,
  kuwait: <Kuwait className="w-4 h-3" />,
  kyrgyzstan: <Kyrgyzstan className="w-4 h-3" />,
  laos: <Laos className="w-4 h-3" />,
  latvia: <Latvia className="w-4 h-3" />,
  lebanon: <Lebanon className="w-4 h-3" />,
  lesotho: <Lesotho className="w-4 h-3" />,
  liberia: <Liberia className="w-4 h-3" />,
  libya: <Libya className="w-4 h-3" />,
  liechtenstein: <Liechtenstein className="w-4 h-3" />,
  lithuania: <Lithuania className="w-4 h-3" />,
  luxembourg: <Luxembourg className="w-4 h-3" />,
  madagascar: <Madagascar className="w-4 h-3" />,
  malawi: <Malawi className="w-4 h-3" />,
  malaysia: <Malaysia className="w-4 h-3" />,
  maldives: <Maldives className="w-4 h-3" />,
  mali: <Mali className="w-4 h-3" />,
  malta: <Malta className="w-4 h-3" />,
  "marshall-i": <MarshallIslands className="w-4 h-3" />,
  mauritania: <Mauritania className="w-4 h-3" />,
  mauritius: <Mauritius className="w-4 h-3" />,
  mexico: <Mexico className="w-4 h-3" />,
  micronesia: <Micronesia className="w-4 h-3" />,
  moldova: <Moldova className="w-4 h-3" />,
  monaco: <Monaco className="w-4 h-3" />,
  mongolia: <Mongolia className="w-4 h-3" />,
  montenegro: <Montenegro className="w-4 h-3" />,
  morocco: <Morocco className="w-4 h-3" />,
  mozambique: <Mozambique className="w-4 h-3" />,
  myanmar: <Myanmar className="w-4 h-3" />,
  namibia: <Namibia className="w-4 h-3" />,
  nauru: <Nauru className="w-4 h-3" />,
  nepal: <Nepal className="w-4 h-3" />,
  netherlands: <Netherlands className="w-4 h-3" />,
  "new-zealand": <NewZealand className="w-4 h-3" />,
  nicaragua: <Nicaragua className="w-4 h-3" />,
  niger: <Niger className="w-4 h-3" />,
  nigeria: <Nigeria className="w-4 h-3" />,
  "north-macedon": <NorthMacedonia className="w-4 h-3" />,
  norway: <Norway className="w-4 h-3" />,
  oman: <Oman className="w-4 h-3" />,
  pakistan: <Pakistan className="w-4 h-3" />,
  palau: <Palau className="w-4 h-3" />,
  panama: <Panama className="w-4 h-3" />,
  "papua-n-g": <PapuaNewGuinea className="w-4 h-3" />,
  paraguay: <Paraguay className="w-4 h-3" />,
  peru: <Peru className="w-4 h-3" />,
  philippines: <Philippines className="w-4 h-3" />,
  poland: <Poland className="w-4 h-3" />,
  portugal: <Portugal className="w-4 h-3" />,
  qatar: <Qatar className="w-4 h-3" />,
  romania: <Romania className="w-4 h-3" />,
  russia: <Russia className="w-4 h-3" />,
  rwanda: <Rwanda className="w-4 h-3" />,
  "saint-k-n": <SaintKN className="w-4 h-3" />,
  "saint-lucia": <SaintLucia className="w-4 h-3" />,
  "saint-v-g": <SaintVG className="w-4 h-3" />,
  samoa: <Samoa className="w-4 h-3" />,
  "san-marino": <SanMarino className="w-4 h-3" />,
  "sao-tome-p": <SaoTomeP className="w-4 h-3" />,
  "saudi-arabia": <SaudiArabia className="w-4 h-3" />,
  senegal: <Senegal className="w-4 h-3" />,
  serbia: <Serbia className="w-4 h-3" />,
  seychelles: <Seychelles className="w-4 h-3" />,
  "sierra-leone": <SierraLeone className="w-4 h-3" />,
  singapore: <Singapore className="w-4 h-3" />,
  slovakia: <Slovakia className="w-4 h-3" />,
  slovenia: <Slovenia className="w-4 h-3" />,
  "solomon-i": <SolomonIslands className="w-4 h-3" />,
  somalia: <Somalia className="w-4 h-3" />,
  "south-africa": <SouthAfrica className="w-4 h-3" />,
  "south-sudan": <SouthSudan className="w-4 h-3" />,
  spain: <Spain className="w-4 h-3" />,
  "sri-lanka": <SriLanka className="w-4 h-3" />,
  sudan: <Sudan className="w-4 h-3" />,
  suriname: <Suriname className="w-4 h-3" />,
  sweden: <Sweden className="w-4 h-3" />,
  switzerland: <Switzerland className="w-4 h-3" />,
  syria: <Syria className="w-4 h-3" />,
  taiwan: <Taiwan className="w-4 h-3" />,
  tajikistan: <Tajikistan className="w-4 h-3" />,
  tanzania: <Tanzania className="w-4 h-3" />,
  thailand: <Thailand className="w-4 h-3" />,
  "timor-leste": <TimorLeste className="w-4 h-3" />,
  togo: <Togo className="w-4 h-3" />,
  tonga: <Tonga className="w-4 h-3" />,
  "trinidad-t": <TrinidadT className="w-4 h-3" />,
  tunisia: <Tunisia className="w-4 h-3" />,
  turkey: <Turkey className="w-4 h-3" />,
  turkmenistan: <Turkmenistan className="w-4 h-3" />,
  tuvalu: <Tuvalu className="w-4 h-3" />,
  uganda: <Uganda className="w-4 h-3" />,
  ukraine: <Ukraine className="w-4 h-3" />,
  uae: <UnitedArabEmirates className="w-4 h-3" />,
  uk: <UnitedKingdom className="w-4 h-3" />,
  "us-east": <UnitedStates className="w-4 h-3" />,
  "us-west": <UnitedStates className="w-4 h-3" />,
  "us-central": <UnitedStates className="w-4 h-3" />,
  uruguay: <Uruguay className="w-4 h-3" />,
  uzbekistan: <Uzbekistan className="w-4 h-3" />,
  vanuatu: <Vanuatu className="w-4 h-3" />,
  venezuela: <Venezuela className="w-4 h-3" />,
  vietnam: <Vietnam className="w-4 h-3" />,
  yemen: <Yemen className="w-4 h-3" />,
  zambia: <Zambia className="w-4 h-3" />,
  zimbabwe: <Zimbabwe className="w-4 h-3" />,
};
