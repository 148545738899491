import { Avatar } from "@spheron/ui-library";

interface IProps {
  cellIcon?: JSX.Element;
  titleText: string;
  subtitleText?: string;
  classname?: string;
}

const TableTokenCard = ({
  cellIcon,
  titleText,
  subtitleText,
  classname = "",
}: IProps) => {
  return (
    <div className={`flex flex-row gap-2 items-center ${classname}`}>
      {cellIcon && cellIcon}
      <div className="flex flex-col">
        <span className="text-base-para-text-color dark:text-dark-base-para-text-color text-[14px] leading-5 font-400">
          {titleText}
        </span>
        {subtitleText && (
          <span className=" text-base-icon dark:text-dark-base-icon text-[13px] leading-3 font-400">
            {subtitleText}
          </span>
        )}
      </div>
    </div>
  );
};

export default TableTokenCard;
