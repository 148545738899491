import { TableCellProps } from "@spheron/ui-library";
import NvidiaIcon from "@spheron/ui-library/dist/assets/gpu-nvidia.svg";
import AmdIcon from "@spheron/ui-library/dist/assets/gpu-amd.svg";
import GoldIcon from "@/assets/podium-icons/gold.svg";
import SilverIcon from "@/assets/podium-icons/silver.svg";
import BronzeIcon from "@/assets/podium-icons/bronze.svg";
import CPUIcon from "@spheron/ui-library/dist/assets/compute/cpu.svg";
import RAMIcon from "@spheron/ui-library/dist/assets/compute/ram.svg";
import GpuIcon from "@spheron/ui-library/dist/assets/compute/gpu.svg";
import StorageIcon from "@spheron/ui-library/dist/assets/compute/storage.svg";
import GlobeIcon from "@spheron/ui-library/dist/assets/ball-2.svg";
import { FlagMap } from "./flag-config";

export const leaderboardTableHeaders: TableCellProps[] = [
  {
    id: 1,
    title: "Rank",
    tableCellType: "header",
  },
  {
    id: 2,
    title: "Name",
    tableCellType: "header",
  },
  {
    id: 2.1,
    title: "Version",
    tableCellType: "header",
  },
  {
    id: 3,
    title: "Region",
    tableCellType: "header",
  },
  {
    id: 4,
    title: "Status",
    tableCellType: "header",
  },
  {
    id: 5,
    title: "Trust Tier",
    tableCellType: "header",
  },
  {
    id: 6,
    title: "Rewards/Era",
    tableCellType: "header",
  },
  {
    id: 7,
    title: "Total Rewards",
    tableCellType: "header",
  },
  {
    id: 8,
    title: "Resources",
    tableCellType: "header",
  },
];

export const getGpuIcon = (gpu: string) => {
  switch (gpu.toLowerCase()) {
    case "amd":
      return <AmdIcon className="w-6 h-6" />;
    case "nvidia":
    default:
      return <NvidiaIcon className="w-6 h-6" />;
  }
};

export const getIcon = (resource: string) => {
  switch (resource?.toLowerCase()) {
    case "gpu":
      return <GpuIcon className="w-5 h-5 text-inherit -mt-1" />;
    case "cpu":
    default:
      return <CPUIcon className="w-5 h-5 text-inherit -mt-0.5" />;
    case "storage":
      return (
        <StorageIcon className="w-5 h-5 text-inherit mt-0.5 -mb-1 -mr-1" />
      );
    case "ram":
      return <RAMIcon className="w-5 h-5 text-inherit" />;
  }
};

export const getPodiumIcon = (podiumNumber: number) => {
  switch (podiumNumber) {
    case 1:
      return <GoldIcon className="w-9 h-10" />;
    case 2:
      return <SilverIcon className="w-9 h-10" />;
    case 3:
      return <BronzeIcon className="w-9 h-10" />;

    default:
      <></>;
  }
};

export const getRegionIcon = (region: string) => {
  if (FlagMap[region]) return FlagMap[region];
  return <GlobeIcon className="w-4 h-4" />;
};
